import React, { useState } from "react"
import Star from "../../../../assets/images/store/CesStar.svg"
import Hubspote from "../../../Hubspote"
const AIHeroSection = () => {
  const [isHubSpot, setIsHubSpot] = useState(false)
  const handleHubspot = () => {
    setIsHubSpot(!isHubSpot)
    document.body.style.overflow = 'hidden';
  }
  return (
    <>
      {isHubSpot&&
        <Hubspote handleHubspot={setIsHubSpot} isHubSpot={isHubSpot} />
      }
      <div
        className="min-h-screen flex items-center justify-center text-white bg-cover bg-center "
        style={{
          backgroundImage: `url("https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2025/01/CES-AI-Hero-Section.webp")`,
        }}
      >
        <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-20 pb-32 text-center">
          <div className="flex justify-center mb-4">
            <span
              style={{
                borderRadius: "40px",
                border: "4px solid rgba(255, 255, 255, 0.1)",
              }}
            >
              <button
                className="group relative flex items-center gap-2 px-2 sm:px-6 md:px-10 py-3 rounded-full text-white font-medium text-sm sm:text-base transition-all duration-300 shadow-lg"
                style={{
                  background:
                    "linear-gradient(93deg, #FF33DA -7.96%, #A329CC 39.05%, #1E7BE4 83.37%, #73C0FF 115.59%)",
                }}
              >
                <span className="flex items-center justify-center w-6 h-6 rounded-full bg-white/20 ">
                  <img src={Star} alt="Star Icon" className="w-8 h-8" />
                </span>
                <span>We Innovate, We Automate, We Empower.</span>
              </button>
            </span>
          </div>

          <h1 className="text-3xl md:text-6xl font-bold my-4 md:mb-4 text-center leading-snug md:leading-tight">
            Shaping USA Businesses Smarter with Our AI Excellence
          </h1>
          <p className="text-xl font-medium text-center">
            Unlock potential, upgrade capabilities, streamline operations, and elevate customer experience of your business with us.
          </p>
          <div className="flex justify-center lg:justify-center w-full">
            <span
              className="my-4 md:my-8 lg:mt-6"
              style={{
                borderRadius: "40px",
                border: "4px solid rgba(255, 255, 255, 0.1)",
              }}
            >
              <button
                className=" bg-yellow-500 relative px-8 py-3 text-base leading-6 font-semibold text-shark-500 hover:bg-yellow-600 rounded-full"
                onClick={handleHubspot}
                >
                Book Your Free Consultation
              </button>
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

export default AIHeroSection
