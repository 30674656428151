import React from "react"
import H2 from "../../core/H2"
import P from "../../core/P"

export const MyTeams = ({ MyTeams }) => {
  // console.log(MyTeams, "MyTeams")
  const { title, member } = MyTeams

  return (
    <div className="flex justify-center">
      <div className="px-4 sm:px-6 max-w-screen-xl lg:px-12 lg:py-28 md:py-20 py-10">
        <div className="mx-auto text-center mb-10" data-aos="fade-up">
          {title && <H2 title={title} />}
          {title && (
            <P
              title={`Driving the organization by strength and wisdom. Believing to Set clear expectations, always deliver, and never break trust.`}
              className="text-shark-400"
            />
          )}
        </div>
        <div
          className="grid lg:grid-cols-4 lg:gap-5 md:grid md:grid-cols-4 sm:grid-cols-2 grid-cols-1 md:gap-6 "
          data-aos="fade-up"
        >
          {member.map(
            ({ MyTeamsBlock: { firstname, lastname, bio, photo } }) => (
              <div className="w-full mt-5" key={Math.random()}>
                {photo && (
                  <div className="flex justify-center">
                    <div className="flex items-center ">
                      <img
                        src={photo?.mediaItemUrl}
                        height={photo?.mediaDetails?.height}
                        width={photo?.mediaDetails?.width}
                        loading="lazy"
                        alt={`${firstname} ${lastname}`}
                        title={`${firstname} ${lastname}`}
                        className="rounded-full lg:h-28 lg:w-28 md:h-28 md:w-28 h-20 w-20 "
                      />
                    </div>
                  </div>
                )}
                <P
                  title={`${firstname}&nbsp;${lastname}`}
                  noPad
                  className="text-shark-500 mt-5 text-center justify-center text-sm font-semibold"
                />
                <P
                  title={bio}
                  className="text-blue-500  text-center text-sm "
                  noPad
                />
              </div>
            )
          )}
        </div>
      </div>
    </div>
  )
}

export default MyTeams
