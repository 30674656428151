import React from "react"
import loadable from "@loadable/component"
const MapComponent = loadable(() => import("../MapComponent"))
const CareerBlock = loadable(() => import("../CareerBlock"))
// const GetInTouch = loadable(() => import("../GetInTouch"))
const IndustryFocus = loadable(() => import("../IndustryFocus"))
const Associations = loadable(() => import("../Associations"))
const ExtendedContactForm = loadable(() =>
  import("../ExtendedContactForm/NewExtendedContactForm")
)
const HiringBlock = loadable(() => import("../HiringBlock"))
const GITEX_SECTION = loadable(() => import("../GitexSection/NewGitex"))
const CES_SECTION = loadable(() => import("../CES/CESSection"))
const CES_NEWSECTION = loadable(() => import("../CES/CESCalender"))
const CES_MAP = loadable(() => import("../CES/CESMap"))
const CES_SINGLE_PROFILE = loadable(() => import("../CES/CESSingleProfile"))
const CES_VIDEO_PLAYER = loadable(() => import("../CES/CESVideoPlayer"))
const GITEX_TWO_SECTION = loadable(() => import("../GitexTwoSection"))
const SimpleCalendly = loadable(() => import("../Forms/PopupCalandly"))
const GitexBtn = loadable(() => import("../GitexShortCode/GitexBtn"))
const GITEX_SECTION_NEW = loadable(() => import("../GitexSectionNew"))
const SINGLE_PROFILE = loadable(() => import("../SingleProfile"))
const AI_SECTION = loadable(() => import("../CESAI/AISliderSection"))
const AI_HERO_SECTION = loadable(() => import("../CESAI/AIHeroSection"))
const AI_SECTION_SILDER = loadable(() => import("../CESAI/AISectionSlider"))
const AI_SILDER = loadable(() => import("../CESAI/AIApproach"))
const AI_INDUSTRIES = loadable(() => import("../CESAI/AIIndustries"))
const AI_TECHSTACK = loadable(() => import("../CESAI/AITechStack"))
const CASE_STUDY_SLIDER = loadable(() => import("../CESAI/CaseStudy"))
const AI_PROFILESECTION = loadable(() => import("../CESAI/AIProfileSection"))
const AIML_SECTION = loadable(() => import("../AIML/AiMlFeature"))
export const ShortCode = ({ attributes }) => {
  const { text } = attributes
  switch (text) {
    case "WMT_MAP":
      return <MapComponent />
    case "WMT_CAREER":
      return <CareerBlock />
    case "WMT_INDUSTRYFOCUS":
      return <IndustryFocus />
    case "WMT_ASSOCIATIONS":
      return <Associations />
    case "WMT_CONTACTFORM":
      return <ExtendedContactForm />
    case "WMT_FRESHERS":
      return <HiringBlock />
    case "WMT_CES_SECTION":
      return <CES_SECTION />
    case "WMT_CES_NEWSECTION":
      return <CES_NEWSECTION />
    case "WMT_CES_MAP":
      return <CES_MAP />
    case "WMT_CES_SINGLE_PROFILE":
      return <CES_SINGLE_PROFILE />
    case "WMT_CES_VIDEO_PLAYER":
      return <CES_VIDEO_PLAYER />
    case "WMT_AI_SECTION":
      return <AI_SECTION />
    case "WMT_AI_HEROSECTION":
      return <AI_HERO_SECTION />
    case "WMT_AI_SECTION_SILDER":
      return <AI_SECTION_SILDER />
    case "WMT_AI_SILDER":
      return <AI_SILDER />
    case "WMT_AI_PROFILE_SECTION":
      return <AI_PROFILESECTION/>
    case "WMT_AI_INDUSTRIES":
      return <AI_INDUSTRIES />
    case "WMT_AI_TECHSTACK":
      return <AI_TECHSTACK />
    case "WMT_CASE_STUDY_SLIDER":
      return <CASE_STUDY_SLIDER />
    case "WMT_GITEX_SECTION":
      return <GITEX_SECTION />
    case "WMT_GITEX_SECTION_FORM":
      return <SimpleCalendly />
    case "WMT_GITEX_SECTION_BTN":
      return <GitexBtn />
    case "WMT_GITEX_SECTION_NEW":
      return <GITEX_SECTION_NEW />
    case "WMT_TWO_GITEX_SECTION":
      return <GITEX_TWO_SECTION />
    case "WMT_SINGLE_PROFILE":
      return <SINGLE_PROFILE />
    case "WMT_AIML_SECTION":
      return <AIML_SECTION />
    default:
      break
  }
}

export default ShortCode
