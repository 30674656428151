import React, { useState, useEffect } from "react"
import BlogPopup from "../../../components/blocks/Forms/BlogPopup"
import H2 from "../../core/H2"
import P from "../../core/P"

const CTAButtonBroucher = ({ title, subtitle, action1, action2 }) => {
  const [blogPopup, setBlogPopup] = useState(false)
  const [iframePopup, setIframePopup] = useState(false)
  const currentUrl = typeof window !== 'undefined' ? window.location.href : '';
  const extractedText = action1?.url?.startsWith("#") ? action1?.url?.substring(1) : action1?.url;
  return (
    <>
      {/* Conditional Popup */}
      {blogPopup && <BlogPopup setIframePopup={setIframePopup} setBlogPopup={setBlogPopup} pageurl={currentUrl} type={extractedText} oldBrochureType={extractedText}/>}
      <div className="cta-bg-image flex items-center justify-center h-full w-full bg-blue-500">
        <div
          className="flex flex-col place-items-center items-center text-center py-10 m-4 aos-init aos-animate leading-10"
          data-aos="fade-up"
          data-aos-easing="ease-out-back"
          data-aos-duration="700"
        >
          <H2
            title={title}
            theme="#FFFFFF"
            className="lg:max-w-screen-lg max-w-lg  text-center"
          />
          <P title={subtitle} className="text-white" />
          <div className="mt-8 flex lg:flex-shrink-0">
            {action1 && (
              <div className="inline-flex rounded-full ">
                <button
                  className="inline-flex relative px-8 py-3 text-base leading-6 font-medium text-shark-500 bg-yellow-500 hover:bg-yellow-600 rounded"
                  onClick={() => setBlogPopup(true)}
                >
                  {action1.title}
                </button>
              </div>
            )}
            {action2 && (
              <div className="ml-3 inline-flex rounded-full">
                <a
                  href={action2.url}
                  className="inline-flex relative px-8 py-3 text-base leading-6 font-medium text-yellow-500 hover:text-yellow-600 bg-transparent border border-yellow-500 hover:border-yellow-600 rounded"
                >
                  {action2.title}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default CTAButtonBroucher
