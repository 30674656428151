import React, { useRef, useState } from "react"
import Slider from "react-slick"
import "./casestudyslider.css"
const CaseStudySlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0)

  const cards = [
    {
      id: 1,
      title: "Melly App",
      subtitle: "Mental Wellness App Powered by AI Insights",
      description: (
        <>
          <ul className="list-disc pl-4 space-y-3 lg:space-y-6">
            <li>AI-powered journaling and therapy tools for mental wellness.</li>
            <li>            Cognitive Behavioral Therapy (CBT) exercises to manage stress and negative thoughts.</li>
            <li>AI-driven personality assessments and tailored exercises for personal growth.</li>
            <li>MellyCoach provides personalized insights and advice based on emotional and journal data.</li>
          </ul>
        </>
      ),
      image: {
        mediaItemUrl:
          "https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2024/12/melly.webp",
        altText: "Melly App Screenshot",
      },
      ctaLink: {
        url: "https://webmobtech.com/casestudy/mental-wellness-app/",
        title: "Case Study",
        //target: "_blank",
      },
    },
    {
      id: 2,
      title: "CRM for Real Estate",
      subtitle: "AI Powered CRM & Lead Management Software",
      description: (
        <>
          <ul className="list-disc pl-4 space-y-3 lg:space-y-6">
            <li>AI-powered CRM for real-time business management and deeper customer connections.</li>
            <li>Intelligent lead routing, in-app communication, and calendar synchronization.</li>
            <li>AI-generated transcripts with sentiment and tone analysis for lead conversations.</li>
            <li>ML-driven lead preference predictions for enhanced sales efficiency.</li>
            
          </ul>
        </>
      ),
      image: {
        mediaItemUrl:
          "https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2025/01/CRM.webp",
        altText: "Arkiv App Screenshot",
      },
      ctaLink: {
        url:
          "https://webmobtech.com/casestudy/real-estate-crm-software-solution/",
        title: "Case Study",
        //target: "_blank",
      },
    },

    {
      id: 3,
      title: "Arkiv 360",
      subtitle: "AI-powered app for image recognition and text extraction",
      description: (
        <>
          <ul className="list-disc pl-4 space-y-3 lg:space-y-6">
            <li>AI-powered app combining OCR, HWR, and computer vision for seamless text and object recognition.</li>
            <li>Fine-tuned deep learning models for enhanced accuracy and real-time performance.</li>
            <li>Semantic search capabilities for efficient image retrieval and data organization.</li>
            <li>Adaptive AI leveraging pre-trained models for continuous learning and improved results.</li>
          </ul>
        </>
      ),
      image: {
        mediaItemUrl:
          "https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2024/12/Arkiv-360-2.webp",
        altText: "Arkiv App Screenshot",
      },
      ctaLink: {
        url: "https://webmobtech.com/casestudy/image-recognition-app/",
        title: "Case Study",
        //target: "_blank",
      },
    },
    {
      id: 4,
      title: "Xeedle",
      subtitle:
        "AI-driven tool automating XPath generation for effortless testing",
      description: (
        <>
          <ul className="list-disc pl-4 space-y-3 lg:space-y-6">
            <li>AI-driven tool automating XPath generation for seamless and accurate testing.</li>
            <li>Supports cross-browser compatibility with Chrome and Firefox for enhanced flexibility.</li>
            <li>Advanced scrap history management for efficient reuse and sharing of test data.</li>
            <li>AI-powered insights and learning resources to simplify QA testing processes.</li>
          </ul>
        </>
      ),
      image: {
        mediaItemUrl:
          "https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2024/12/Xeedle.webp",
        altText: "Arkiv App Screenshot",
      },
      ctaLink: {
        url: "https://webmobtech.com/casestudy/testing-automation-tool/",
        title: "Case Study",
        //target: "_blank",
      },
    },
    {
      id: 5,
      title: "QuestNQuiz",
      subtitle: "AI-generated quiz maker App",
      description: (
        <>
          <ul className="list-disc pl-4 space-y-3 lg:space-y-6">
            <li>AI-powered quiz creation with keyword-based generation and adjustable difficulty levels.</li>
            <li>Innovative 'Scan and Make' feature using AI to convert scanned text into quizzes automatically.</li>
            <li>AI algorithms ensure precise keyword extraction and contextually relevant question generation.</li>
            <li>Adaptive AI analyzes user preferences to refine quiz recommendations and improve engagement.</li>
          </ul>
        </>
      ),
      image: {
        mediaItemUrl:
          "https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2024/12/Questnquiz.webp",
        altText: "Arkiv App Screenshot",
      },
      ctaLink: {
        url: "https://webmobtech.com/casestudy/quiz-app-development/",
        title: "Case Study",
        //target: "_blank",
      },
    },
  ]

  const textSliderRef = useRef(null)
  const imageSliderRef = useRef(null)

  const textSliderSettings = {
    dots: false,
    arrows: false,
    fade: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: imageSliderRef.current, // Synchronize with image slider
    beforeChange: (_, next) => setCurrentSlide(next), // Update current slide
  }

  const imageSliderSettings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: textSliderRef.current, // Synchronize with text slider
  }
  const handleClick = url => {
    window.open(url, "_self"); // This opens the link in the same tab.
  }

  const next = () => {
    textSliderRef.current.slickNext()
    imageSliderRef.current.slickNext()
  }

  const previous = () => {
    textSliderRef.current.slickPrev()
    imageSliderRef.current.slickPrev()
  }

  return (
    <div className="">
      <div className="flex flex-col lg:flex-row px-4 sm:px-6 w-full lg:px-12 bg-white py-4 sm:py-6 lg:py-12 casestudy-height">
        <div
          className="h-full w-full lg:w-1/2 p-6 lg:p-4 lg:border-2 border-[rgba(44,35,232,0.2)] order-1 lg:order-2 custom-ai-rounded custom-ai-rounded-lg space-y-6 flex flex-col justify-between"
          style={{ overflow: "hidden" }} // Ensures no scrollbar
        >
          <section className="rounded-lg lg:h-full lg:flex lg:flex-col lg:justify-center">
            <Slider
              ref={imageSliderRef}
              {...imageSliderSettings}
              className="image-slider"
            >
              {cards.map(card => (
                <div key={card.id} className="flex justify-center items-center h-full w-full"
                  style={{ overflow: "hidden" }}
                >
                  <img
                    src={card.image.mediaItemUrl}
                    alt={card.image.altText || "Solution"}
                    className="rounded-r-3xl w-full h-full object-cover"
                  />
                </div>
              ))}
            </Slider>
          </section>
        </div>
        <div
          className="lg:flex flex-col h-full w-full lg:w-1/2 p-4 lg:p-10 custom-rounded custom-rounded-lg justify-between custom-scrollbar
      lg:border-2 border-[rgba(44,35,232,0.2)]  order-1 lg:order-1"
        >
          <nav className="h-full w-full">
            <Slider
              ref={textSliderRef}
              {...textSliderSettings}
              className="text-slider"
            >
              {cards.map(card => (
                <div key={card.id} className="p-2">
                  <h3 className="text-3xl md:text-5xl font-bold text-shark-500">
                    {card.title}
                  </h3>
                  <div className="lg:mt-4 md:mt-3 mt-2 font-semibold text-blue-500 text-lg md:text-3xl">
                    {card.subtitle}
                  </div>
                  <div className="text-xl font-semibold text-shark-500 mt-8 lg:mt-12">
                    {card.description}
                  </div>
                  <div className="flex justify-center lg:justify-start w-full cursor-pointer relative">
                    <button
                      className="my-4 md:my-8 z-10 lg:mt-6 bg-yellow-500 relative px-8 py-3 text-base leading-6 font-semibold text-shark-500 cursor-pointer hover:bg-yellow-600 rounded-full"
                      onClick={() => handleClick(card.ctaLink.url)}
                    >
                      Case Study
                    </button>
                  </div>
                </div>
              ))}
            </Slider>
          </nav>
          <div className="flex-grow"></div>{" "}
          {/* This spacer helps ensure even margin distribution */}
        </div>
        
      </div>
      <div className="flex justify-center items-center gap-4 pb-12 bg-white">
        <button
          type="button"
          onClick={previous}
          className="w-12 h-12 rounded-full border border-blue-500 bg-transparent flex items-center justify-center hover:bg-white/10 transition-colors"
        >
          <i
            className={`icon-slider-left-arrow text-black flex justify-center py-3 mx-0 active:text-blue-500`}
            style={{ fontSize: "20px" }}
          />
        </button>
        {/* <span className="text-blue-400 text-lg font-bold">
          {currentSlide + 1} /{" "}
          <span className="text-shark-500 text-lg font-bold opacity-50">
            {cards.length}
          </span>
        </span> */}
        <button
          type="button"
          onClick={next}
          className="w-12 h-12 rounded-full border border-blue-500 bg-transparent flex items-center justify-center hover:bg-white/10 transition-colors"
        >
          <i
            className={`icon-slider-right-arrow text-black flex justify-center py-3 mx-0 active:text-blue-500`}
            style={{ fontSize: "20px" }}
          />
        </button>
      </div>
    </div>
  )
}

export default CaseStudySlider
