import React, { useState } from "react"
import GitexPopupModal from "../../../core/GitexPopupModal"
import GoogleMapReact from "google-map-react"
import mapstyle from "../../../../components/blocks/MapComponent/mapstyle"
import "./CESMap.css"
const Marker = ({ icon }) => (
  <div>
    <img src={icon} alt="WebMob Tech" title="WebMob Tech" loading="lazy" />
  </div>
)
const defaultProps = {
  center: {
    lat: 36.1304777,
    lng: -115.149225,
  },
  zoom: 15,
}

const CESMap = () => {
  // const [openGitex, setOpenGitex] = useState(false)
  // const openGitexModal = () => {
  //   setOpenGitex(true)
  // }
  // const closeGitexModal = () => {
  //   setOpenGitex(false)
  // }
  return (
    <>
      {/* {openGitex && (
        <GitexPopupModal
          open={openGitex}
          closeGitexModal={closeGitexModal}
          url={"https://calendly.com/webmob/lets-connect-at-ces-2025"}
        />
      )} */}
      <div className="px-4 sm:px-6 w-screen lg:px-12 bg-white py-4 sm:py-6 lg:py-12">
        <div className="flex flex-col lg:flex-row gap-8 items-center">
          {/* Map Section */}
          <div className="w-full custom-height p-4 lg:p-16">
          <div className="w-full h-full border rounded-3xl overflow-hidden">
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyCDfeCfbqNuivaaVTXCdznoL-eOINcPuF4",
                }}
                center={defaultProps.center}
                defaultZoom={defaultProps.zoom}
                options={{
                  styles: [...mapstyle],
                  zoomControl: false,
                  streetViewControl: false,
                  panControl: false,
                  scrollwheel: false,
                }}
              >
                <Marker
                  lat={36.1304777}
                  lng={-115.149225}
                  icon="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2022/04/marker.png"
                />
              </GoogleMapReact>
            {/* <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d53463713.340727866!2d-115.1492250!3d36.1304777!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c8c46f33042393%3A0xcc9f7c1f1976a0b3!2sLas%20Vegas%20Convention%20Center%2C%20North%20Hall!5e1!3m2!1sen!2sin!4v1730790409225!5m2!1sen!2sin&z=50&output=embed&disable-scrollwheel=true&interactive=false"
                width="100%"
                height="500px"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                title="Google Map"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe> */}
            </div>
          </div>
          <div className="w-full space-y-6">
            <div>
              <h2 className="font-bold lg:text-5xl text-3xl text-shark-500 mb-6">
                Where to Find Us at{" "}
                <span
                  className="bg-gradient-to-r from-cyan-300 to-green-400 text-transparent bg-clip-text font-extrabold"
                  style={{
                    backgroundImage:
                      "linear-gradient(90deg, #00A0DC 51.81%, #DEDF1F 76.81%)",
                  }}
                >
                  CES 2025
                </span>
              </h2>
              <div className="mb-6">
                <p className="font-medium lg:text-xl md:text-xl text-lg text-shark-500">
                  Mark your calendar for <b>January 7-10, 2025</b>, and
                  experience the future of tech with us. Join WebMobTech where
                  groundbreaking ideas meet innovation.
                  <br />
                  <br />
                  Plan your visit and explore our latest solutions with ease.
                  Follow the <b>Get Directions</b> link below.
                </p>
              </div>
            </div>

            <div className="space-y-4">
              <h2 className="font-bold  lg:text-5xl text-3xl text-shark-500 mb-6">
                Location
              </h2>
              <div className="flex flex-col md:flex-row items-start md:items-center gap-4">
                {/* Dates with icon */}
                <div className="flex items-center font-medium lg:text-xl md:text-xl text-lg text-shark-500">
                  <svg
                    className="w-5 h-5 mr-2 text-blue-600"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M19 2H18V1C18 0.734784 17.8946 0.48043 17.7071 0.292893C17.5196 0.105357 17.2652 0 17 0C16.7348 0 16.4804 0.105357 16.2929 0.292893C16.1054 0.48043 16 0.734784 16 1V2H8V1C8 0.734784 7.89464 0.48043 7.70711 0.292893C7.51957 0.105357 7.26522 0 7 0C6.73478 0 6.48043 0.105357 6.29289 0.292893C6.10536 0.48043 6 0.734784 6 1V2H5C3.67441 2.00159 2.40356 2.52888 1.46622 3.46622C0.528882 4.40356 0.00158786 5.67441 0 7L0 8H24V7C23.9984 5.67441 23.4711 4.40356 22.5338 3.46622C21.5964 2.52888 20.3256 2.00159 19 2Z" />
                    <path d="M0 19C0.00158786 20.3256 0.528882 21.5964 1.46622 22.5338C2.40356 23.4711 3.67441 23.9984 5 24H19C20.3256 23.9984 21.5964 23.4711 22.5338 22.5338C23.4711 21.5964 23.9984 20.3256 24 19V10H0V19ZM17 14.5C17.2967 14.5 17.5867 14.588 17.8334 14.7528C18.08 14.9176 18.2723 15.1519 18.3858 15.426C18.4993 15.7001 18.5291 16.0017 18.4712 16.2926C18.4133 16.5836 18.2704 16.8509 18.0607 17.0607C17.8509 17.2704 17.5836 17.4133 17.2926 17.4712C17.0017 17.5291 16.7001 17.4994 16.426 17.3858C16.1519 17.2723 15.9176 17.08 15.7528 16.8334C15.588 16.5867 15.5 16.2967 15.5 16C15.5 15.6022 15.658 15.2206 15.9393 14.9393C16.2206 14.658 16.6022 14.5 17 14.5ZM12 14.5C12.2967 14.5 12.5867 14.588 12.8334 14.7528C13.08 14.9176 13.2723 15.1519 13.3858 15.426C13.4994 15.7001 13.5291 16.0017 13.4712 16.2926C13.4133 16.5836 13.2704 16.8509 13.0607 17.0607C12.8509 17.2704 12.5836 17.4133 12.2926 17.4712C12.0017 17.5291 11.7001 17.4994 11.426 17.3858C11.1519 17.2723 10.9176 17.08 10.7528 16.8334C10.588 16.5867 10.5 16.2967 10.5 16C10.5 15.6022 10.658 15.2206 10.9393 14.9393C11.2206 14.658 11.6022 14.5 12 14.5ZM7 14.5C7.29667 14.5 7.58668 14.588 7.83336 14.7528C8.08003 14.9176 8.27229 15.1519 8.38582 15.426C8.49935 15.7001 8.52906 16.0017 8.47118 16.2926C8.4133 16.5836 8.27044 16.8509 8.06066 17.0607C7.85088 17.2704 7.58361 17.4133 7.29264 17.4712C7.00166 17.5291 6.70006 17.4994 6.42597 17.3858C6.15189 17.2723 5.91762 17.08 5.7528 16.8334C5.58797 16.5867 5.5 16.2967 5.5 16C5.5 15.6022 5.65804 15.2206 5.93934 14.9393C6.22064 14.658 6.60218 14.5 7 14.5Z" />
                  </svg>
                  <p>7 to 10 January 2025</p>
                </div>

                {/* Location with icon */}
                <div className="flex items-center font-medium lg:text-xl md:text-xl text-lg  text-shark-500">
                  <svg
                    className="w-5 h-5 mr-2 text-blue-600"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 0.0419922C9.35398 0.0449033 6.81712 1.09727 4.94598 2.96822C3.07484 4.83918 2.02222 7.37593 2.01904 10.022C2.01904 12.592 4.00904 16.614 7.93404 21.976C8.4013 22.6161 9.0131 23.1369 9.71964 23.4959C10.4262 23.8549 11.2075 24.042 12 24.042C12.7926 24.042 13.5739 23.8549 14.2804 23.4959C14.987 23.1369 15.5988 22.6161 16.066 21.976C19.991 16.614 21.981 12.592 21.981 10.022C21.9779 7.37593 20.9252 4.83918 19.0541 2.96822C17.183 1.09727 14.6461 0.0449033 12 0.0419922ZM12 14C11.2089 14 10.4356 13.7654 9.77776 13.3259C9.11997 12.8863 8.60728 12.2616 8.30452 11.5307C8.00177 10.7998 7.92256 9.99556 8.0769 9.21963C8.23124 8.44371 8.61221 7.73098 9.17162 7.17157C9.73103 6.61216 10.4438 6.23119 11.2197 6.07685C11.9956 5.92251 12.7999 6.00172 13.5308 6.30448C14.2617 6.60723 14.8864 7.11992 15.3259 7.77771C15.7654 8.43551 16 9.20887 16 9.99999C16 11.0609 15.5786 12.0783 14.8285 12.8284C14.0783 13.5786 13.0609 14 12 14Z" />
                  </svg>
                  <p>Booth 9542, North Hall, LVCC</p>
                </div>
              </div>
            </div>

            <div className="">
              <button
                className="bg-yellow-500 relative px-8 py-3 text-base leading-6 font-semibold text-shark-500 hover:bg-yellow-600 rounded"
                onClick={() =>
                  //https://www.google.com/maps/dir//Las+Vegas+Convention+Center,+North+Hall,+Convention+Center+Dr,+Las+Vegas,+NV+89109,+United+States/@39.9992575,-123.0502364,4167158m/data=!3m2!1e3!5s0x80c8c4665d150741:0xcedf47f456a54bb1!4m8!4m7!1m0!1m5!1m1!1s0x80c8c46f33042393:0xcc9f7c1f1976a0b3!2m2!1d-115.1532135!2d36.1327171?entry=ttu&g_ep=EgoyMDI0MTAyOS4wIKXMDSoASAFQAw%3D%3D
                  window.location.href = "https://exhibitors.ces.tech/8_0/floorplan/?hallID=A&selectedBooth=9542"
                }
              >
                Get Directions
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CESMap
