import React from "react"
import P from "../../core/P"
import H3 from "../../core/H3"
import Icon from "../../core/Icon"

export const ImageWithText = ({ feature, size, theme }) => {
  const SectionA = e => (
    <>
      {e.icon ? (
        <div className="h-72 lg:h-full w-full flex justify-center items-center overflow-hidden flex-grow">
          <div className="w-full h-full">
            <img
              src={e.icon?.mediaItemUrl}
              loading="lazy"
              height={e.icon?.mediaDetails?.height}
              width={e.icon?.mediaDetails?.width}
              alt={e.icon?.altText || ""}
              title={e.title}
              className="w-full h-full object-cover text-center rounded-3xl"
            />
          </div>
        </div>
      ) : (
        <>
          {e.fontello && (
            <Icon
              iconName={e.fontello}
              className="text-left flex justify-start pb-5 mx-0"
              theme={theme}
            />
          )}
          {e.title && (
            <H3
              title={e.title}
              className={`font-bold text-left text-shark-400 ${
                e.description ? "pb-5" : " "
              }`}
              textSize="text-lg"
            />
          )}
          {e.description && (
            <P
              title={e.description}
              className="text-left text-shark-400 flex-grow"
              noPad
            />
          )}
        </>
      )}
    </>
  )

  const SectionB = e => (
    <>
      {e.icon ? (
        <div className="h-72 lg:h-full w-full flex justify-center items-center overflow-hidden flex-grow">
          <div className="w-full h-full">
            <img
              src={e.icon?.mediaItemUrl}
              loading="lazy"
              height={e.icon?.mediaDetails?.height}
              width={e.icon?.mediaDetails?.width}
              alt={e.icon?.altText || ""}
              title={e.title}
              className="w-full h-full object-cover text-center rounded-3xl"
            />
          </div>
        </div>
      ) : (
        <>
          {e.fontello && (
            <i
              className={`${e.fontello} w-20 h-20 md:w-24 md:h-24 lg:w-26 lg:h-26 rounded-2xl text-blue-500 flex items-center justify-center mb-6 icon`}
              style={{
                background: "rgba(51, 95, 255, 0.10)",
                fontSize: "70px"
              }}
            />
          )}
          {e.title && (
            <H3
              title={e.title}
              className={`font-bold text-left ${e.description ? "pb-5" : " "}`}
              textSize="text-lg"
            />
          )}
          {e.description && (
            <P title={e.description} className="text-left flex-grow" noPad />
          )}
        </>
      )}
    </>
  )

  return (
    <div className="flex justify-center bg-blue-200 ">
      <div className="px-4 sm:px-6 max-w-7xl lg:px-12">
        <div
          className={`lg:grid lg:grid-cols-${size} lg:gap-6 `}
          data-aos="fade-up"
        >
          {feature.map(e => {
            const pointer = e.cta?.url ? "cursor-pointer" : "cursor-default"
            return theme ? (
              <div
                className={`shadow-lg text-center ${
                  e.icon ? "p-0" : "p-6"
                } my-6 lg:my-0 bg-white rounded-3xl ${
                  e.icon
                    ? "transition border-white hover:shadow-xl hover:-translate-y-1"
                    : "transition duration-200 ease-in-out icon-card"
                }`}
                key={Math.random()}
              >
                {e.cta?.url ? (
                  <a
                    href={e.cta?.url ? e.cta.url : "#"}
                    className={`${pointer}`}
                  >
                    {SectionA(e)}
                  </a>
                ) : (
                  <>{SectionA(e)}</>
                )}
              </div>
            ) : (
              <div
                className={`shadow-lg text-center ${
                  e.icon ? "p-0" : "p-6"
                } my-6 lg:my-0 bg-white rounded-3xl ${
                  e.icon
                    ? "transition hover:border-blue-500 border-white hover:shadow-xl hover:-translate-y-1 text-shark-400"
                    : "hover:bg-blue-500 hover:shadow-xl text-shark-400 hover:text-white transition duration-200 ease-in-out icon-card"
                }`}
                key={Math.random()}
              >
                {e.cta?.url ? (
                  <a
                    href={e.cta?.url ? e.cta.url : "#"}
                    className={`${pointer}`}
                  >
                    {SectionB(e)}
                  </a>
                ) : (
                  <>{SectionB(e)}</>
                )}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default ImageWithText
