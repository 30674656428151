import React, { useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'
import ContainerWrapper from '../../core/ContainerWrapper'
import H2 from '../../core/H2'
import H3 from '../../core/H3'
import P from '../../core/P'
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"

const FullPageSlider = ({ SectionSliderBlock, index }) => {
    const { cards = [], title, subTitle } = SectionSliderBlock
    const [sliderIndex, setSliderIndex] = useState(1)
    const [newIndex, setNewIndex] = useState(1)
    const customeSlider = useRef(null);
    const photosliderRef = useRef(null);

    const previous = () => {
        customeSlider.current.slickPrev();
    };
    const next = () => {
        customeSlider.current.slickNext();
    };
    useEffect(() => {
        photosliderRef.current.slickGoTo(sliderIndex);
        customeSlider.current.slickGoTo(sliderIndex);
        setNewIndex(sliderIndex + 1)
    }, [sliderIndex]);

    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        pauseOnHover: true,
        arrows: false,
        fade: false,
        beforeChange: (oldIndex, newIndex) => {
            setSliderIndex(newIndex)
        }
    }
    const settings2 = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 4000,
        pauseOnHover: true,
        arrows: false,
        fade: true,
        beforeChange: (oldIndex, newIndex) => {
            setSliderIndex(newIndex)
        }
    }
    return (
        <ContainerWrapper className="bg-white lg:flex" index={index}>
            <div className='lg:grid lg:grid-cols-2 grid-cols-1 overflow-hidden max-w-7xl content-center aos-init aos-animate' data-aos="fade-up" data-aos-easing="ease-out-back" data-aos-duration="700">
                <div className='order-1 lg:order-0 flex flex-col justify-center content-center'>
                    <Slider
                        {...settings2}
                        ref={photosliderRef}
                        className="custom-slick-slider slider slider__container section-slider-block "
                    >
                        {
                            cards && (
                                cards.map(({ image }, key) => {
                                    return <div key={key} className="flex  justify-center items-center">
                                        <div className='flex items-center max-w-xl mx-auto lg:p-10'>

                                            <img
                                                src={image?.mediaItemUrl}
                                                alt={image?.altText ? image?.altText : "Solution"}
                                                //title={image?.altText ? image?.altText : "Solution"}
                                                loading="lazy"
                                                height={image?.mediaDetails?.height}
                                                width={image?.mediaDetails?.width}
                                                className={`outline-none max-w-full w-full h-auto`}
                                            />
                                        </div>
                                    </div>
                                })
                            )
                        }
                    </Slider>
                </div>
                <div className='order-2 lg:order-1 max-w-2xl  mx-auto flex flex-col justify-center  content-center pt-10 lg:pt-0'>
                    <div className="flex justify-between mx-auto lg:mx-0 w-52 lg:w-56 items-center relative lg:top-0 z-100 pb-2  ">
                        <button type='button' onClick={previous} className='border flex justify-center items-center w-14 h-14 rounded-full  hover:text-white border-blue-500 bg-white hover:bg-blue-500 transition-all duration-100'>
                            <i
                                className={`icon-slider-left-arrow flex justify-center py-3 mx-0 active:text-blue-500`}
                                style={{ fontSize: "20px" }}
                            />
                        </button>
                        <span className='lg:text-xl text-sm text-blue-400 font-semibold rounded px-6 py-2'>  {newIndex} <span className='text-shark-400'>/ {cards.length}</span></span>
                        <button type='button' onClick={next} className='border flex justify-center items-center w-14 h-14 rounded-full  hover:text-white border-blue-500 bg-white hover:bg-blue-500 transition-all duration-100'>
                            <i
                                className={`icon-slider-right-arrow flex justify-center py-3 mx-0 active:text-blue-500`}
                                style={{ fontSize: "20px" }}
                            />
                        </button>
                    </div>
                    {title && <P className="font-normal text-shark-400 text-sm md:text-xl lg:text-2xl text-center lg:text-left" noCenter title={title}></P>}
                    <H2 className="text-blue-500 text-center lg:text-left" title={subTitle}></H2>
                    <Slider
                        {...settings}
                        ref={customeSlider}
                        className="custom-slick-slider slider slider__container "
                    >
                        {
                            cards && (
                                cards.map(({ title,subtitle, description, ctaLink, ctaLink2, pageDetailLink }, key) => {
                                    return <div key={key}>
                                        <div className='lg:max-w-lg 2xl:max-w-xl  mx-left'>
                                            {title &&
                                            <a href={pageDetailLink?.url} target={pageDetailLink?.target} rel="noopener noreferrer">
                                                <H3 className="lg:mt-6 md:mt-5 mt-4 text-shark-500 max-w-sm mx-auto lg:mx-0 text-center lg:text-left " title={` ${title}`} />
                                            </a>}
                                            {subtitle && <P className="lg:mt-6 md:mt-5 mt-4 font-semibold text-blue-500 text-sm md:text-lg lg:text-2xl max-w-sm mx-auto lg:mx-0 text-center lg:text-left" noCenter title={subtitle} noPad></P>}
                                            <P className="text-shark-400 mx-auto lg:mx-0 text-center lg:text-left text-sm md:text-lg lg:text-xl m-0 pt-0 max-w-sm " title={description} />
                                        </div>
                                        <div
                                            className={`mt-10  flex flex-col md:flex-row justify-center items-center lg:justify-start`}
                                        >
                                            {
                                                ctaLink && (
                                                    <div className="inline-flex rounded-full mr-4">
                                                        <a
                                                            href={ctaLink?.url}
                                                            className="inline-flex bg-blue-500 relative px-8 py-3 text-base leading-6 font-medium text-white hover:bg-blue-600 rounded border border-transparent"
                                                            target={ctaLink?.target}
                                                        >
                                                            {ctaLink?.title}
                                                        </a>
                                                    </div>
                                                )
                                            }
                                            {
                                                ctaLink2 && (
                                                    <div className=" mt-5 md:mt-0 inline-flex rounded-full ">
                                                        <a
                                                            href={ctaLink2?.url}
                                                            className="inline-flex bg-transparent relative px-8 py-3 text-base leading-6 font-medium text-blue-500 hover:text-blue-600 rounded border border-blue-500 hover:border-blue-600"
                                                            target={ctaLink2?.target}
                                                        >
                                                            {ctaLink2?.title}
                                                        </a>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                })
                            )
                        }
                    </Slider>
                </div>
               
            </div>
        </ContainerWrapper>
    )
}

export default FullPageSlider   