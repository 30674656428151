import React, { useState } from "react"
import LinkedIn from "../../../../assets/images/store/LinkedIn Default.svg"
import GitexPopupModal from "../../../core/GitexPopupModal"
import LinkedInhover from "../../../../assets/images/store/LinkedIn On Hover.svg"
import Hubspote from "../../../Hubspote"
const AIProfileSection = () => {
  const [hoverState, setHoverState] = useState({
    Pramesh: false,
    Bharat: false,
    Brad: false,
    Luke: false,
  })
  const [isTwitterHovered, setIsTwitterHovered] = useState(false)
  const [isHubSpot, setIsHubSpot] = useState(false)
  const handleHubspot = () => {
    setIsHubSpot(!isHubSpot)
    document.body.style.overflow = 'hidden';
  }
  const handleMouseEnter = name => {
    setHoverState(prev => ({
      ...prev,
      [name]: true,
    }))
  }

  const handleMouseLeave = name => {
    setHoverState(prev => ({
      ...prev,
      [name]: false,
    }))
  }
  const team = [
    {
      name: "Pramesh Jain",
      title: "Founder & CEO",
      image:
        "https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2024/11/PrameshSir.png",
      linkedin: "https://www.linkedin.com/in/prameshjain/",
    },
    {
      name: "Bharat Patel",
      title: "VP - Operations & Delivery ",
      image:
        "https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2024/12/Bharat-Patel.webp",
      linkedin: "https://www.linkedin.com/in/bharat-patel-1500303/",
    },
    {
      name: "Brad Bolton",
      title: "USA Director",
      image:
        "https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2024/11/Brad-Bolton.webp",
      linkedin: "https://www.linkedin.com/in/brad-bolton-98773b48/",
    },
    {
      name: "Luke Monroe",
      title: "USA Director",
      image:
        "https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2024/11/Luke-Monroe.webp",
      linkedin: "https://www.linkedin.com/in/lucasmonroe/",
    },
  ]
  return (
    <>
      {isHubSpot&&
        <Hubspote handleHubspot={setIsHubSpot} isHubSpot={isHubSpot} />
      }
      <div className="w-full bg-[#F8F9FF] py-12">
        <div className="font-serif font-bold lg:text-5xl text-3xl  tracking-normal text-center text-shark-500">
          Meet the Visionaries Driving Innovation at{" "}
          <span className="text-blue-500">WebMobTech</span>
        </div>
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center mb-12">
            <div className="lg:text-xl md:text-xl text-lg text-center font-medium text-shark-500 mt-4">
              Our leadership team consists of visionaries and experts who steer
              WebMobTech toward success. With decades of experience and a
              commitment to excellence, they lead with integrity, setting
              ambitious goals and delivering results. Their strategic insight,
              paired with an unwavering focus on innovation and trust, ensures
              we continue to drive growth and exceed client expectations on
              every project.
            </div>
            <div className="mt-8  flex justify-center">
              <button
                className="bg-yellow-500 relative px-8 py-3 text-base leading-6 font-semibold text-shark-500  hover:bg-yellow-600 rounded"
                onClick={handleHubspot}
              >
                Schedule a Meeting
              </button>
            </div>{" "}
          </div>

          <div className="grid md:grid-cols-4 gap-12 mt-16">
            {team.map(member => (
              <div key={member.name} className="flex flex-col items-center">
                <div className="self-center lg:w-52 lg:h-52 md:w-52 md:h-52 w-40 h-40">
                  <img
                    src={member.image}
                    alt={member.name}
                    width="180"
                    className="w-60 rounded-full"
                    height="180"
                    loading="lazy"
                  />
                </div>
                <div className="text-2xl font-bold text-shark-500 pt-8">
                  {member.name}
                </div>
                <div className="text-lg font-medium text-shark-500 mt-2">
                  {member.title}
                </div>
                <div className="flex mt-4">
                  <a
                    href={member.linkedin}
                    className="py-2 inline-block text-white"
                    target="_blank"
                    rel="noreferrer"
                    onMouseEnter={() => handleMouseEnter(member.name)}
                    onMouseLeave={() => handleMouseLeave(member.name)}
                  >
                    <img
                      src={hoverState[member.name] ? LinkedInhover : LinkedIn}
                      alt="LinkedIn"
                      className="h-8"
                      loading="lazy"
                      width="32px"
                      height="32px"
                    />
                    <span className="sr-only">LinkedIn</span>
                  </a>
                  {/* <a
                    href={member.twitter}
                    className="py-2 inline-block text-white ml-5 "
                    target="_blank"
                    rel="noreferrer"
                    onMouseEnter={() => setIsTwitterHovered(true)}
                    onMouseLeave={() => setIsTwitterHovered(false)}
                  >
                    <img
                      src={isTwitterHovered ? Twitterhover : Twitter}
                      alt="Twitter"
                      className="h-8"
                      loading="lazy"
                      width="32px"
                      height="32px"
                    />
                    <span className="sr-only">Twitter</span>
                  </a> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default AIProfileSection
