import React, { useState, useEffect } from "react"

const AITechStack = () => {
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  })

  const handleResize = () => {
    setIsDesktop(window.innerWidth < 650)
  }

  return (
    <>
      <div className="relative bg-blue-500">
        <div
          className=" inset-0 bg-cover w-full"
          // style={{
          //   backgroundImage:
          //     'url("https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2024/12/Tech-stack-1.webp")',
          // }}
        >
          <div className="relative h-full max-w-screen-2xl mx-auto px-4 py-16">
            <div className="text-center mb-8">
              <h1
                className="text-2xl md:text-5xl font-bold text-white px-6 md:px-10 py-1 md:py-2 inline-block"
                style={{
                  borderRadius: "40px",
                  border: "6px solid rgba(255, 249, 249, 0.08)",
                  background: "rgba(255, 255, 255, 0.16)",
                }}
              >
                Tech Stacks for Quality AI Services
              </h1>
            </div>
            <div className="text-center mb-12">
              <p className="text-lg text-white max-w-3xl mx-auto">
                Our expertise spans healthcare, finance, and e-commerce, where
                we deliver innovative solutions that enhance efficiency and
                customer experience across these industries.
              </p>
            </div>
            <div className="flex justify-center">
            <img
              src={isDesktop
                ? "https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2024/12/Techstack-Mobile-View.webp"
                : "https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2024/12/Techstack-Web.webp"
              }
              alt="Industries"
              className="max-w-full h-auto object-cover rounded-lg"
            />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AITechStack
