import React from "react"
import './AimlFeature.css';
const AiMlFeature = () => {
  const industries = [
    {
      title: "Healthcare",
      description:
        "AI can help diagnose conditions, predict patient needs, and analyze medical data more accurately.",
      icon:(
        <i className="icon-ai-ml-development-services-healthcare" style={{
          fontSize: "var(--icon-size)",
          width: "var(--icon-size)",
          height: "var(--icon-size)"
        }}/>
      ),
    },
    {
      title: "Finance",
      description:
        "From fraud detection to credit scoring, AI is being used to automate processes and reduce risks.",
      icon: (
        <i className="icon-ai-ml-development-services-finance" style={{
          fontSize: "var(--icon-size)",
          width: "var(--icon-size)",
          height: "var(--icon-size)"
        }}/>
      ),
    },
    {
      title: "E-commerce",
      description:
        "AI boosts engagement, smarter search, and insights to increase sales.",
      icon: (
        <i className="icon-ai-ml-development-services-e-commerce" style={{
          fontSize: "var(--icon-size)",
          width: "var(--icon-size)",
          height: "var(--icon-size)"
        }}/>
      ),
    },
    {
      title: "Manufacturing",
      description:
        "AI helps optimize production schedules, predict maintenance needs, and enhance supply chain efficiency.",
      icon: (
        <i className="icon-ai-ml-development-services-manufacturing" style={{
          fontSize: "var(--icon-size)",
          width: "var(--icon-size)",
          height: "var(--icon-size)"
        }}/>
      ),
    },
    {
      title: "Retail",
      description:
        "AI helps with demand forecasting, inventory management, and creating personalized shopping experiences.",
      icon: (
        <i className="icon-ai-ml-development-services-retail" style={{
          fontSize: "var(--icon-size)",
          width: "var(--icon-size)",
          height: "var(--icon-size)"
        }}/>
      ),
    },
    {
      title: "Logistics",
      description:
        "Optimize delivery routes, manage inventory, and improve operational efficiency with AI solutions.",
      icon: (
        <i className="icon-ai-ml-development-services-logistics" style={{
          fontSize: "var(--icon-size)",
          width: "var(--icon-size)",
          height: "var(--icon-size)"
        }}/>
      ),
    },
  ]

  return (
    <div
      className="min-h-screen flex items-center justify-center text-white bg-cover bg-center relative"
      style={{
        backgroundImage: `url("https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2025/01/businessman-search-new-opportunities-international-business-typing-laptop-background-world-map-hologram-scaled.jpg")`,
      }}
    >
      {/* Dark Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>

      <div className="relative z-10 p-6 md:p-16 rounded-lg w-full">
        {/* Title */}
        <h1 className="text-3xl md:text-5xl font-bold my-4 md:mb-6 text-center text-white">
          How AI & ML Can Help Your Industry
        </h1>

        {/* Subtitle */}
        <p className="text-lg md:text-xl text-gray-300 text-center  mx-auto mb-8">
          AI and machine learning are becoming crucial across many industries.
          Our AI/ML development services are designed to help you solve specific
          challenges in your sector, improve operations, and stay competitive.
        </p>

        {/* Industry Cards */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 w-full mx-auto gap-4">
          {industries.map((industry, index) => (
            <div className="feature-tag">
              <div className="flex flex-col items-start h-full">
                <div
                  className="w-20 h-20 md:w-24 md:h-24 lg:w-26 lg:h-26 rounded-2xl text-white flex items-center justify-center mb-6 text-2xl md:text-4xl lg:text-6xl"
                  style={{
                    background: "rgba(255, 255, 255, 0.10)",
                  }}
                >
                  {industry.icon}
                </div>
                <h3 className="text-xl lg:text-2xl font-bold text-white mb-4">
                  {industry.title}
                </h3>
                <div className="text-left font-normal text-lg text-white overflow-hidden">
                  {industry.description}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default AiMlFeature
