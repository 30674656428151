import React, { useEffect, useState } from "react"
import H2 from "../../core/H2"
import P from "../../core/P"
import BlogPopup from "../Forms/BlogPopup"

export const PDFViewer = ({ PDFViewerBlock }) => {
  const { pdfLink,title,subtitle } = PDFViewerBlock
  const [blogPopup, setBlogPopup] = useState(false)
  const [iframePopup, setIframePopup] = useState(false)
  const currentUrl = typeof window !== 'undefined' ? window.location.href : '';
  const pathSegment = typeof window !== 'undefined' ? window.location.pathname.split('/').filter(Boolean).pop() : '';
  useEffect(() => {
    window.open(pdfLink, '_blank');
    const isFormSubmitted = localStorage.getItem("form_submission_success");
    if (isFormSubmitted === "true") {
      setIframePopup(true)
      setBlogPopup(false)
    } else {
      setIframePopup(false)
      setBlogPopup(true)
    }
  }, [])
  return (
    <>
      {blogPopup && <BlogPopup setIframePopup={setIframePopup} setBlogPopup={setBlogPopup} pageurl={currentUrl} type={""} oldBrochureType={pathSegment}/>}
      <div
        className="max-w-screen-xl mx-auto text-center py-12 px-4 bg-transparent"
        data-aos="fade-up"
      >
        <div data-scroll data-scroll-speed="2" className="py-40">
          <H2 title={title} />
          <P title={subtitle} className="text-shark-400" />
        </div>
        {iframePopup && (
          <div className="min-w-full">
            <iframe
              title="Company Profile"
              className="w-full h-screen "
              src={pdfLink}
              allowFullScreen="true"
              webkitallowfullscreen="true"
            />
          </div>
        )}
      </div>
    </>
  )
}

export default PDFViewer
